import { Injectable } from '@tanbo/vue-di-plugin'

import { HttpService } from '@/services/http.service'

export interface checkDataFormTy {
  /** 业务名称 */
  soft_type: number
  /** 渠道号 */
  accid: string
  /** 留存率 */
  retention: string
  /** 开始时间 */
  start: string
  /** 结束时间 */
  end: string
}
export interface channelSoftInfosTY {
  softid: string
  softname: string
}

export interface channelAccidTY {
  accid: string
  tag: string
}

export interface ChannelRequestData {
  data?: channelSoftInfosTY[]
}

export interface ChannelAccidRequestData {
  data?: channelAccidTY[]
}

export interface SttleInfoRequestData {
  data?: channelAccidTY[]
}

export interface AccidRequestParamsTY {
  soft_type: number
}

/* 表单规范 */
export const CheckDataFormRulesData = {
  soft_type: [
    {
      required: true,
      message: '请选择业务名称',
      trigger: 'change',
    },
  ],
  accid: [
    {
      required: true,
      message: '请选择渠道号',
      trigger: 'change',
    }
  ],
  retention: [
    {
      required: false,
      message: '请选择留存率',
      trigger: 'change',
    }
  ],
  start: [
    {
      type: 'date',
      required: true,
      message: '请选择开始时间',
      trigger: 'change',
    },
  ],
  end: [
    {
      type: 'date',
      required: true,
      message: '请选择结束时间',
      trigger: 'change',
    },
  ]
}

/* 留存选择数据列 */
export const ChannelRetentionData = [
  {
    retention: '0',
    retentionText: '所有留存'
  },
  {
    retention: '1',
    retentionText: '15%以下'
  },
  {
    retention: '2',
    retentionText: '15% ~ 20%'
  },
  {
    retention: '3',
    retentionText: '20% ~ 30%'
  },
  {
    retention: '4',
    retentionText: '30% ~ 40%'
  },
  {
    retention: '5',
    retentionText: '40%以上'
  }
]

@Injectable()
export class CheckDataService {
  constructor (private http: HttpService) {}

  doGetChannelSoft () {
    return this.http.post<ChannelRequestData>('/get_channel_soft_infos.json', {}).then((response) => {
      return response
    })
  }

  doGetChannelAccid (params: AccidRequestParamsTY) {
    return this.http.post<ChannelAccidRequestData>('/get_channels_accid.json', params).then((response) => {
      return response
    })
  }

  doGetSttleInfo (params: checkDataFormTy) {
    return this.http.post<SttleInfoRequestData>('/get_settle_info.json', params).then((response) => {
      return response
    })
  }
}
