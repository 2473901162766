
import { defineComponent, reactive, ref, computed, onBeforeMount, onUnmounted } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'

import MainLayout from '@/components/layout/mainLayout/mainLayout.vue'
import Tips from '@/components/tips/tips.vue'

import {
  checkDataFormTy,
  channelAccidTY,
  CheckDataFormRulesData,
  ChannelRetentionData,
  CheckDataService
} from './checkData.service'
import { SoftService, channelSoftInfosTY } from '@/services/soft.service'

import { accAdd } from '@/utils/add'
import { getDateStr } from '@/utils/time'
import { ExportExcel } from '@/utils/csv'

import { ElMessage } from 'element-plus'

export default defineComponent({
  name: '',
  components: {
    MainLayout,
    Tips,
  },
  setup() {
    const injector = useReflectiveInjector([CheckDataService])
    const service = injector.get(CheckDataService)
    const softService = injector.get(SoftService)
    /* 是否展示留存选项 */
    const showRetention = computed(() => {
      if (checkDataForm.accid === '-1') {
        if (checkDataForm.soft_type == 2 || checkDataForm.soft_type == 4) {
          return true
        }
        return false
      }
      return false
    })
    /* 是否展示导出excel */
    const showUpExcel = computed(() => {
      if (checkTableData.value.length > 0) {
        return true
      }
      return false
    })
    /* 是否限制日期的选择 */
    function disabledDate(time: any) {
      if (checkDataForm.soft_type === 1) return
      return time.getTime() < new Date('2021-11-1 00:00:00').getTime()
    }

    const checkDataForm:checkDataFormTy = reactive({
      soft_type: 0,
      accid: '-1',
      retention: '0',
      start: getDateStr(-1, true),
      end: getDateStr(-1)
    })
    let setTimeFunc: any = null
    /* 业务名称 */
    const channelSoftInfos = ref<channelSoftInfosTY[]>([] as channelSoftInfosTY[])
    /* 订阅业务名称 */
    const subscription = softService.channelSoftInfos.subscribe(list => {
      if (list.length > 0) {
        channelSoftInfos.value = list
        checkDataForm.soft_type = list[0].softid
        setTimeFunc = setTimeout(() => { // 表单校验
          setDefaults()
        },0)
      }
    })
    onUnmounted(() => {
      // 组件销毁时，取消订阅
      subscription.unsubscribe()
      if (setTimeFunc) {
        clearTimeout(setTimeFunc)
      }
    })
    /* 渠道号 */
    const channelAccid = ref<channelAccidTY[]>([] as channelAccidTY[])
    /* 获取各产品的渠道号 */
    function getChannelAccid (val:number) {
      const parse = {
        soft_type: val
      }
      service.doGetChannelAccid(parse).then((response) => {
        channelAccid.value = response?.data || [] as channelAccidTY[]
      }).catch((err) => {
        ElMessage.error(`获取各产品的渠道号失败,${err.msg}`)
      })
    }
    /* 留存选择数据列 */
    const channelRetention = ChannelRetentionData
    /* 表单规范 */
    const checkDataFormRules =  CheckDataFormRulesData
    const checkDataFormRef = ref()

    const tableTag = ref(false)
    /* 查询 */
    function onSubmit () {
      checkDataFormRef.value.validate((valid: boolean) => {
        if (!valid) return // 校验表单
        if (checkDataForm.accid === '-1') {
          tableTag.value = true
        } else {
          tableTag.value = false
        }
        service.doGetSttleInfo(checkDataForm).then((response) => {
          checkTableData.value = response?.data.reverse() || []
          ElMessage.success('查询数据成功')
        }).catch((err) => {
          ElMessage.error(`查询数据失败,${err.msg}`)
        })
      })
    }
    /* 表尾求和 */
    function getSummaries (param:any) {
      const { columns, data } = param
      const sums: any[] = []
      columns.forEach((column: any, index: any) => {
        if (index === 0) {
          sums[index] = '可结算总计'
          return
        }
        const propertyName = column.property
        if (!(propertyName === 'settle_predict' || propertyName === 'settle' || propertyName === 'money')) {
          sums[index] = '--'
          return
        }
        const values = data.map((item: any) => {
          if (item.state !== 0) { // 不结算
            if (column.property === 'settle_predict' || column.property === 'settle' || column.property === 'money') {
              return 0
            }
          }
          return Number(item[column.property])
        })
        if (!values.every((value: any) => isNaN(value))) {
          sums[index] = `${values.reduce((prev: any, curr: any) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return accAdd(prev, curr).toFixed(2)
            }
            return prev
          }, 0)}`
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }
    /* 设置默认值 */
    function setDefaults() {
      getChannelAccid(checkDataForm.soft_type)
      onSubmit()
    }
    /* 导出EXCEL */
    function upExcel () {
      ExportExcel('/get_settle_info.csv', checkDataForm)
    }
    /* 表格数据 */
    const checkTableData = ref<any>([])

    return {
      showRetention,
      showUpExcel,
      checkDataForm,
      checkDataFormRef,
      channelSoftInfos,
      disabledDate,
      getChannelAccid,
      channelAccid,
      channelRetention,
      checkDataFormRules,
      tableTag,
      onSubmit,
      upExcel,
      getSummaries,
      checkTableData
    };
  }
});
